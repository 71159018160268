<!--Vista para cambiar contraseña. Se resive un token y se envia la nueva contraseña.-->
<template>
  <v-container
    fluid
    class="
      pb-0
      px-0
      password-recover-form
      d-flex
      align-space-between
      flex-column
      mb-5
    "
  >
    <v-spacer></v-spacer>
    <v-form v-model="validPasswordReset" ref="passwordResetForm">
      <v-row
        justify="center"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-7' : ''"
      >
        <v-col cols="10" sm="6" lg="4" xl="3">
          <v-row>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" class="d-flex">
           
            </v-col>
            <v-col cols="12" class="d-flex">
              <p class="display-1 text-center font-weight-bold">
                Ingrese la nueva contraseña
              </p>
            </v-col>
            <v-col cols="12" class="d-flex">
              <v-text-field
                v-model="userData.password"
                label="Contraseña"
                :type="showPass ? 'text' : 'password'"
                filled
                rounded
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                :rules="passwordRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex">
              <v-text-field
                v-model="userData.confirmPassword"
                label="Confirme Contraseña"
                :type="showConfirmPass ? 'text' : 'password'"
                filled
                rounded
                :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConfirmPass = !showConfirmPass"
                :rules="[
    userData.password === userData.confirmPassword ||
      'Las contraseña deben ser iguales .',
      userData.password.length > 7 ||
      'La contraseña debe tener al menos 8 caracteres.',
      /[a-z]/.test(userData.password) || 'La contraseña debe incluir al menos una letra minúscula.',
      /[A-Z]/.test(userData.password) || 'La contraseña debe incluir al menos una letra mayúscula.',
      /[^A-Za-z0-9]/.test(userData.password) || 'La contraseña debe incluir al menos un carácter especial.',
  ]"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                color="accent"
                rounded
                block
                @click="_passwordReset()"
                :loading="loadingButton"
                :disabled="userData == '' || !validPasswordReset"
                ><span class="font-weight-black black--text"
                  >Aceptar</span
                ></v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-spacer></v-spacer>
  </v-container>
</template>
<script>
import { validations } from "@/share/validations.js";
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      validPasswordReset: false,
      loadingButton: false,
      showPass: false,
      showConfirmPass: false,
      userData: {
        password: "",
        confirmPassword: "",
        token: "",
      },
      token: "vacia",
      passwordRules: validations.generalFValidation({
        required: true,
        name: "contraseña",
      }),
    };
  },
  methods: {
    ...mapActions("authentication", ["passwordReset", "validateResetToken"]),
    ...mapMutations("notification", ["show"]),

    validatePasswordReset() {
      if (this.$refs.passwordResetForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    async _passwordReset() {
      if (this.validatePasswordReset()) {
        try {
          this.loadingButton = true;
          let response = await this.passwordReset(this.userData);
          if (response.status == true) {
            this.show({
              text: "La contraseña se ha cambiado con exito",
              color: "success",
            });
            this.$router.push("/login");
            //this.$router.push("/recuperar-contrasena");
          } else {
            this.show({
              text: response.message,
              color: "error",
              time: 2000,
            });
          }
          this.loadingButton = false;
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
  async created() {
    try {
      this.userData.token = this.$route.params.token;
      let response = await this.validateResetToken(this.userData.token);
      if (response.status == false) {
        this.show({
          text: response.message,
          color: "error",
        });
        setTimeout(() => {
          this.$router.push("/recuperar-contrasena");
        }, 1500);
      }
    } catch (error) {
      this.show({
        text: "El token inválido, por favor generar otro",
        color: "error",
      });
      setTimeout(() => {
        this.$router.push("/recuperar-contrasena");
      }, 1500);
    }
  },
};
</script>
<style scoped>
.password-reset-form {
  min-height: 73vh;
}
</style>